/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-content {
    --background: var(--ion-color-dark);
}

ion-toolbar {
    --background: #000000;
    --color: #468af8;
}

@font-face {
    font-family: 'Spotify';
    font-style: normal;
    font-weight: normal;
    src: url('/assets/fonts/spotify-regular.ttf');
}

@font-face {
    font-family: 'Spotify';
    font-weight: bold;
    src: url('/assets/fonts/spotify-bold.ttf');
}

@font-face {
    font-family: 'Spotify';
    font-weight: lighter;
    src: url('/assets/fonts/spotify-light.ttf');
}

.my-custom-class .modal-wrapper {
    height: auto;
    width: 50%;
    top: 20%;
    position: absolute;
    display: block;
}

.tBonix-modal-class .modal-wrapper {

    height: 40%;
    width: 80%;
    top: 15%;
    position: absolute;
    display: block;
}